import { Button, Typography, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import HomeIcon from "@material-ui/icons/Home";
import Link from "next/link";

function NavbarMain(props) {
  const [isScrolled, setScrolled] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("beranda");
  const [isMobile, setisMobile] = useState(false);
  const isMob = useMediaQuery({ maxWidth: 1279 });
  const router = useRouter();

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  useEffect(() => {
    if (isMob) {
      setisMobile(isMob);
    } else {
      setisMobile(false);
    }
  }, [isMob]);
  useEffect(() => {
    if (router.pathname === "/DonaturTerkini") {
      setSelectedMenu("donatur-terkini");
    } else if (router.pathname === "/tanya-ustadz") {
      setSelectedMenu("tanya-ustadz");
    } else if (router.pathname === "/berita-dan-program") {
      setSelectedMenu("Berita dan Program");
    } else if (router.pathname === "/Artikel") {
      setSelectedMenu("Artikel");
    } else if (router.pathname === "/TentangKami") {
      setSelectedMenu("tentang-kami");
    }
  }, [router.pathname]);

  const [state, setState] = React.useState({
    right: false,
  });

  const data = () => {
    const data = [
      {
        push: "/DonaturTerkini",
        name: "Donatur Terkini",
      },
    ];

    data.push({
      push: "/tanya-ustadz",
      name: "Tanya Ustadz",
    });

    props.menu.map((m) => {
      data.push({
        name: m.name,
        push: "/cms?category=" + m.id,
      });
    });

    data.push({
      push: "/campaigns",
      name: "Kampanye",
    });

    data.push({
      push: "/TentangKami",
      name: "Tentang Kami",
    });

    return data;
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        style={{
          textAlign: "end",
          padding: 0,
          margin: "16px 19px",
        }}
      >
        <CancelOutlinedIcon style={{ color: "#2DBE78" }} />
        <Typography style={{ fontSize: 14 }}>
          {data().map((item) => (
            <a href={`${item.push}`}>
              <p>{item.name}</p>
            </a>
          ))}
        </Typography>
        <a
          href="/"
          style={{
            fontWeight: 600,
            fontSize: 16,
            marginLeft: 3,
            cursor: "pointer",
            whiteSpace: "nowrap",
            border: "1px solid #4D4D4D",
            padding: "6px 15px",
            borderRadius: 4,
            textTransform: "inherit",
          }}
        >
          Bismillah Donasi Sekarang
        </a>
      </List>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <Grid
            style={{
              display: "flex",
              padding: 15,
              alignItems: "center",
              position: "fixed",
              background: "#FFFFFF",
              width: "100%",
              boxShadow: isScrolled ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
              zIndex: 999,
            }}
          >
            <Grid
              item
              xs={6}
              style={{
                textAlign: "left",
                alignSelf: "center",
                display: "flex",
              }}
            >
              <img
                onClick={() => router.push("/")}
                src="/Frame/logoNavbarTrans.png"
                width={169}
                height={50.5}
                // height={100.5}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "right", alignSelf: "center" }}
            >
              {["right"].map((anchor) => (
                <Grid key={anchor}>
                  <Button onClick={toggleDrawer(anchor, true)}>
                    <Grid
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/Icon/responsive-menu.svg"
                        style={{ width: 30, height: 30 }}
                      />
                      <p
                        style={{
                          margin: 5,
                          color: "#2DBE78",
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        Menu
                      </p>
                    </Grid>
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <div style={{ paddingBottom: 40 }}></div>
        </>
      ) : (
        <div
          style={{
            padding: "21px 106px",
            display: "flex",
            width: "100%",
            height: 72,
            position: "fixed",
            zIndex: 999,
            background: isScrolled ? "#FFFFFF" : "transparent",
            boxShadow: isScrolled ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : null,
            top: 0,
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {isScrolled ? (
              <div
                style={{
                  padding: 2,
                }}
              >
                <img
                  onClick={() => router.push("/")}
                  src="/Frame/logoNavbarTrans.png"
                  width={169}
                  height={50.5}
                  // height={50.5}
                />
              </div>
            ) : (
              <img
                onClick={() => router.push("/")}
                src="/Frame/logoNavbar.png"
                width={169}
                height={50.5}
                // height={100.5}
              />
            )}
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <a href="/">
              <HomeIcon
                onClick={() => router.push("/")}
                style={{
                  color: isScrolled ? "black" : "white",
                  cursor: "pointer",
                }}
              />
            </a>
            <a href="/DonaturTerkini">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: isScrolled ? "#4D4D4D" : "#FAFAFA",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    padding: "0px 10px",
                    margin: "0px 3px",
                  }}
                >
                  Donatur Terkini
                </Typography>
                {selectedMenu === "donatur-terkini" && (
                  <div style={{ alignSelf: "center" }}>
                    <div
                      style={{
                        width: 80,
                        marginTop: 3,
                        marginBottom: -7,
                        border: isScrolled
                          ? "2px solid #4D4D4D"
                          : "2px solid white",
                        borderRadius: 3,
                      }}
                    />
                  </div>
                )}
              </div>
            </a>

            <a href="/tanya-ustadz">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 12px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: isScrolled ? "#4D4D4D" : "#FAFAFA",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    padding: "0px ",
                  }}
                >
                  Tanya Ustadz
                </Typography>

                {selectedMenu === "tanya-ustadz" && (
                  <div style={{ alignSelf: "center" }}>
                    <div
                      style={{
                        width: 98,
                        marginTop: 3,
                        marginBottom: -7,
                        border: isScrolled
                          ? "2px solid #4D4D4D"
                          : "2px solid white",
                        borderRadius: 3,
                      }}
                    />
                  </div>
                )}
              </div>
            </a>

            <a href="/campaigns">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 12px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: isScrolled ? "#4D4D4D" : "#FAFAFA",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    padding: "0px ",
                  }}
                >
                  Kampanye
                </Typography>

                {selectedMenu === "campaigns" && (
                  <div style={{ alignSelf: "center" }}>
                    <div
                      style={{
                        width: 98,
                        marginTop: 3,
                        marginBottom: -7,
                        border: isScrolled
                          ? "2px solid #4D4D4D"
                          : "2px solid white",
                        borderRadius: 3,
                      }}
                    />
                  </div>
                )}
              </div>
            </a>

            {props.menu.map((m) => (
              <a href={"/cms?category=" + m.id}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                      color: isScrolled ? "#4D4D4D" : "#FAFAFA",
                      margin: "0px 2px",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      padding: "0px 13px",
                    }}
                  >
                    {m.name}
                  </Typography>
                  {router.asPath === "/cms?category=" + m.id && (
                    <div style={{ alignSelf: "center" }}>
                      <div
                        style={{
                          width: 80,
                          marginTop: 3,
                          marginBottom: -7,
                          border: isScrolled
                            ? "2px solid #4D4D4D"
                            : "2px solid white",
                          borderRadius: 3,
                        }}
                      />
                    </div>
                  )}
                </div>
              </a>
            ))}
            <a href="/TentangKami">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 12px",
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: isScrolled ? "#4D4D4D" : "#FAFAFA",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    padding: "0px ",
                  }}
                >
                  Tentang Kami
                </Typography>

                {selectedMenu === "tentang-kami" && (
                  <div style={{ alignSelf: "center" }}>
                    <div
                      style={{
                        width: 80,
                        marginTop: 3,
                        marginBottom: -7,
                        border: isScrolled
                          ? "2px solid #4D4D4D"
                          : "2px solid white",
                        borderRadius: 3,
                      }}
                    />
                  </div>
                )}
              </div>
            </a>

            <a
              href="/"
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: isScrolled ? "#4D4D4D" : "#FAFAFA",
                marginLeft: 3,
                cursor: "pointer",
                whiteSpace: "nowrap",
                border: isScrolled ? "1px solid #4D4D4D" : "1px solid #FAFAFA",
                padding: "6px 15px",
                borderRadius: 4,
                textTransform: "inherit",
                display: "flex",
                alignItems: "none",
                justifyContent: "none",
              }}
            >
              <span style={{ color: isScrolled ? "#4D4D4D" : "#FAFAFA" }}>
                Bismillah Donasi Sekarang
              </span>
            </a>
          </Grid>
        </div>
      )}
    </>
  );
}

export default NavbarMain;
