import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import { FaPhoneAlt, FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Footer(props) {
  const [isMobile, setIsMobile] = React.useState(false);
  const isMob = useMediaQuery({ maxWidth: 1024 });
  const router = useRouter();
  React.useEffect(() => {
    if (isMob) {
      setIsMobile(isMob);
    } else {
      setIsMobile(false);
    }
  }, [isMob]);
  const data = [
    { icon: "/Icon/Med-1.svg", link: "http://fb.me/infakonline.id" },
    // { icon: "/Icon/Med-2.svg" },
    // { icon: "/Icon/Med-3.svg" },
    // { icon: "/Icon/Med-4.svg" },
  ];

  const artikelCat = props.menu.map((m) => {
    return {
      name: m.name,
      push: "/cms?category=" + m.id,
    };
  });

  const data2 = [
    { name: "Donatur Terkini", push: "/DonaturTerkini" },
    ...artikelCat,
    { name: "Tentang Kami", push: "/TentangKami" },
  ];

  return (
    <Grid>
      <>
        {isMobile ? (
          <Grid
            style={{
              backgroundImage: "url(/Frame/HomepageMainFrame.png)",
              backgroundSize: "cover",
              width: "100%",
              padding: "33px 21px",
              paddingBottom: 60,
            }}
          >
            <Grid>
              <Grid style={{ justifyContent: "flex-start" }}>
                <Grid style={{ display: "flex", marginBottom: 9 }}>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src="/Frame/Logo2.svg"
                      style={{ width: 52, height: 52 }}
                    />
                    <img
                      style={{ marginTop: 10 }}
                      onClick={() => router.push("/")}
                      src="/Frame/logoNavbar.png"
                      width={169}
                      height={50.5}
                    />
                    <img
                      style={{ marginTop: 10, marginLeft: 20 }}
                      onClick={() => router.push("/")}
                      src="/Frame/bmzizTransparant.png"
                      width={70}
                      height={35.5}
                    />
                  </div>
                </Grid>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    color: "#FAFAFA",
                    marginBottom: 26,
                  }}
                >
                  infakonline.id adalah platform donasi online yang di kelola
                  BM-ZIS Pondok Pesantren Imam Syafi'i yang merupakan bagian
                  dari Yayasan Pendidikan Pondok Pesantren Imam Syafi'i Batam,
                  dan telah resmi sebagai Unit Pengumpul Zakat ( UPZ ) BAZNAS
                  Batam.
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    color: "#FAFAFA",
                    marginBottom: 20,
                    width: "70%",
                  }}
                >
                  Kontak
                </Typography>

                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#FAFAFA",
                    marginBottom: 5,
                    display: "flex",
                  }}
                >
                  <a
                    style={{ color: "#FAFAFA" }}
                    href="https://wa.me/6281276949644"
                    target="_blank"
                  >
                    <FaPhoneAlt
                      size={16}
                      style={{ marginBottom: -2, marginRight: 8, flex: "none" }}
                    />{" "}
                    0812 7694 9644
                  </a>
                </Typography>

                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#FAFAFA",
                    marginBottom: 5,
                    display: "flex",
                  }}
                >
                  <MdEmail
                    size={16}
                    style={{ marginBottom: -2, marginRight: 8, flex: "none" }}
                  />
                  <a
                    style={{ color: "#FAFAFA" }}
                    href="mailto:info@infakonline.id"
                    target="_blank"
                  >
                    info@infakonline.id
                  </a>
                </Typography>

                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#FAFAFA",
                    marginBottom: 5,
                    display: "flex",
                    marginBottom: 26,
                  }}
                >
                  <FaAddressCard
                    size={16}
                    style={{ marginBottom: -2, marginRight: 8, flex: "none" }}
                  />
                  Komplek Ponpes Imam Syafi'i, Kebun Sayur, Sungai Binti,
                  Sagulung, Batam
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Grid style={{ marginBottom: 26 }}>
                {data2.map((item) => (
                  <a href={`${item.push}`}>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        color: "#FAFAFA",
                        marginBottom: 19,
                      }}
                    >
                      {item.name}
                    </Typography>
                  </a>
                ))}
              </Grid>
              <Grid style={{ display: "flex" }}>
                {data.map((item) => (
                  <a href={item.link}>
                    <Grid style={{ marginRight: 7, cursor: "pointer" }}>
                      <img
                        onClick={() => {
                          window.open(item.link, "_blank");
                        }}
                        src={item.icon}
                        style={{ width: 34, height: 34 }}
                      />
                    </Grid>
                  </a>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            style={{
              backgroundImage: "url(/Frame/HomepageMainFrame.png)",
              backgroundSize: "cover",
              width: "100%",
              padding: "25px 92px",
              display: "flex",
            }}
          >
            <Grid item xs={8} style={{ display: "flex" }}>
              <Grid style={{ justifyContent: "flex-start", maxWidth: 450 }}>
                <Grid style={{ display: "flex", marginBottom: 9 }}>
                  <img
                    src="/Frame/Logo2.svg"
                    style={{ width: 52, height: 52 }}
                  />
                  <div
                    style={{
                      marginLeft: 16,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      style={{ marginTop: 10 }}
                      onClick={() => router.push("/")}
                      src="/Frame/logoNavbar.png"
                      width={169}
                      height={50.5}
                    />
                    <img
                      style={{ marginTop: 10 }}
                      onClick={() => router.push("/")}
                      src="/Frame/bmzizTransparant.png"
                      width={70}
                      height={35.5}
                    />
                  </div>
                </Grid>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 13,
                    color: "#FAFAFA",
                  }}
                >
                  infakonline.id adalah platform donasi online yang di kelola
                  BM-ZIS Pondok Pesantren Imam Syafi'i yang merupakan bagian
                  dari Yayasan Pendidikan Pondok Pesantren Imam Syafi'i Batam,
                  dan telah resmi sebagai Unit Pengumpul Zakat ( UPZ ) BAZNAS
                  Batam.
                </Typography>
              </Grid>
              <Grid style={{ paddingLeft: 50, minWidth: 200 }}>
                <Typography
                  onClick={() => router.push(`${item.push}`)}
                  style={{
                    fontSize: 15,
                    fontWeight: 600,
                    marginBottom: 18,
                    color: "#FAFAFA",
                  }}
                >
                  Kontak
                </Typography>

                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#FAFAFA",
                    marginBottom: 5,
                    display: "flex",
                  }}
                >
                  <a
                    style={{ color: "#FAFAFA" }}
                    href="https://wa.me/6281276949644"
                    target="_blank"
                  >
                    <FaPhoneAlt
                      style={{ marginBottom: -2, marginRight: 8, flex: "none" }}
                    />{" "}
                    0812 7694 9644
                  </a>
                </Typography>

                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#FAFAFA",
                    marginBottom: 5,
                    display: "flex",
                  }}
                >
                  <MdEmail
                    style={{ marginBottom: -2, marginRight: 8, flex: "none" }}
                  />
                  <a
                    style={{ color: "#FAFAFA" }}
                    href="mailto:info@infakonline.id"
                    target="_blank"
                  >
                    info@infakonline.id
                  </a>
                </Typography>

                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#FAFAFA",
                    display: "flex",
                  }}
                >
                  <FaAddressCard
                    style={{ marginBottom: -2, marginRight: 8, flex: "none" }}
                  />
                  Komplek Ponpes Imam Syafi'i, Kebun Sayur, Sungai Binti,
                  Sagulung, Batam
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ display: "flex" }}>
              <Grid item xs={6} style={{ marginLeft: 60 }}>
                {data2.map((item) => (
                  <a href={`${item.push}`}>
                    <Typography
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        color: "#FAFAFA",
                        marginBottom: 19,
                        cursor: "pointer",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </a>
                ))}
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {data.map((item) => (
                  <a href={item.link}>
                    <Grid style={{ marginLeft: 7, cursor: "pointer" }}>
                      <img
                        onClick={() => {
                          window.open(item.link, "_blank");
                        }}
                        src={item.icon}
                        style={{ width: 34, height: 34 }}
                      />
                    </Grid>
                  </a>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    </Grid>
  );
}

export default Footer;
