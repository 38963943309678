// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_ENABLE = process.env.SENTRY_ENABLE || true;
const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  "https://112c37cb80d845ab8b90fa8f60140c33@o910762.ingest.sentry.io/5883792";
const SENTRY_TRACE_SAMPLE_RATE =
  parseInt(process.env.SENTRY_TRACE_SAMPLE_RATE) || 1.0;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || "production";

if (SENTRY_ENABLE) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    environment: SENTRY_ENVIRONMENT,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
