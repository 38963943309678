import Head from "next/head";
import "../styles/globals.css";
import React, { useEffect, useState } from "react";
import "../styles/globals.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../theme";
import NavbarMain from "../components/NavbarMain";
import NavbarSecond from "../components/NavbarSecond";
import { useRouter } from "next/router";
import Footer from "../components/Footer";
import { axiosInstance } from "../config/axios-instance";
import "antd/dist/antd.css";

export default function MyApp({ Component, pageProps }) {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    axiosInstance.get("customer/cms/categories").then((res) => {
      setMenu(res.data.data);
    });
  }, []);

  const router = useRouter();

  return (
    <React.Fragment>
      <Head>
        <title>Kemudahan berinfak dengan infakonline.id</title>
        <link rel="shortcut icon" href="/favicon2.ico" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta property="og:type" content="website" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* <div style={{ minHeight: "100%", maxWidth: 9999 }}> */}
        {router.pathname === "/" ? (
          <NavbarMain menu={menu} />
        ) : (
          <NavbarSecond menu={menu} />
        )}
        <CssBaseline />
        <div style={{ minHeight: "80vh" }}>
          <Component {...pageProps} />
        </div>
        <div style={{ position: "relative", bottom: 0 }}>
          <Footer menu={menu} />
        </div>
        {/* </div> */}
      </ThemeProvider>
    </React.Fragment>
  );
}
